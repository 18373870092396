import React, { Fragment } from 'react';
import { withFormik, FormikProps, FormikErrors, ErrorMessage, Form, Field } from 'formik';
import { Button, LinearProgress } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { styled } from '@material-ui/core/styles';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Link,
    Redirect,
} from 'react-router-dom';
import './LoanForm.css';

interface FormValues {
    email: string;
    password: string;
}

interface OtherProps {
    message: string;
}

interface LoanFormProps {
    initialEmail?: string;
}

const required = (value: any) => (value ? undefined : 'Required');

const LoanFormButton = styled(Button)({
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
});

const LoanFormField = styled(TextField)({
    border: '1px solid #666'
});

const Page1 = () => (
    <Fragment>
        <div>
            <label>Email</label>
            <Field component={LoanFormField} type="email" name="email" />
        </div>
        <Link to="/step2">
            <button type="button">Next »</button>
        </Link>
    </Fragment>
);


const Page2 = () => (
    <Fragment>
        <div>
            <label>Favorite Color</label>
            <Field name="favoriteColor" component="select">
                <option value="">Select a Color</option>
                <option value="#ff0000">❤️ Red</option>
                <option value="#00ff00">💚 Green</option>
                <option value="#0000ff">💙 Blue</option>
            </Field>
            <ErrorMessage
                name="favoriteColor"
                component="div"
                className="field-error"
            />
        </div>
        <Link to="/step1">
            <button type="button">Previous</button>
        </Link>
        <button type="submit">Submit</button>
    </Fragment>
);

// @ts-ignore
const LoanForm = ({ values, handleSubmit }) => (
    <Router>
        <div className="App">
            <h1>Multistep / Form Wizard </h1>
            <form onSubmit={handleSubmit}>
                <Switch>
                    <Route
                        path="/step1"
                        render={routeProps => <Page1 />}
                    />
                    <Route
                        path="/step2"
                        render={routeProps => <Page2  />}
                    />
                    <Redirect to="/step1" />
                </Switch>
            </form>
        </div>
    </Router>
);

export default withFormik<LoanFormProps, FormValues>({
    mapPropsToValues: props => {
        return {
            email: props.initialEmail || '',
            password: '',
        };
    },

    validate: (values: FormValues) => {
        let errors: FormikErrors<FormValues> = {};
        if (!values.email) {
            errors.email = 'Required';
        }
        return errors;
    },

    handleSubmit: values => {
        // do submitting things
    },

    displayName: 'LoanForm',
})(LoanForm);